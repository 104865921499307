import vuexStore from '@/store';
import { defineStore } from 'pinia';
import { useRenewalsStore } from '@/stores/leasing/renewals';
import { useStorage } from '@vueuse/core';

export const useLeasingStore = defineStore('leasing', {
    state: () => ({
        renewalsStore: useRenewalsStore(),
        payerByPartyIdMap: {},
        temporaryCharges: useStorage('temporaryCharges', []),
    }),

    getters: {
        hasPermission: () => permissionAlias =>
            permissionAlias && vuexStore.getters['auth/getAppPermissions']('LEASING')?.includes(permissionAlias),
    },

    actions: {
        setPayerByPartyIdMap(payload) {
            this.payerByPartyIdMap = payload;
        },

        setTemporaryCharge(payload) {
            this.temporaryCharges = [...this.temporaryCharges, payload];
        },

        resetTemporaryCharges() {
            this.temporaryCharges = [];
        },
    },
});
